<template>
  <div class="chat-container">
    <Chat/>
  </div>
</template>

<script>
import Chat from './Chat'

export default {
  name: 'Widget',
  components: {
    Chat
  }
}
</script>

<style scoped lang="scss">
</style>
