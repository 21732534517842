<template>
  <div id="buyGet" class="buy-get" :class="{ opened: openPrizes }" v-if="getStatus && userIsParticipant">
    <StatusBar @open="openPrizes = !openPrizes" v-if="!openPrizes"/>
    <Prizes :status="getStatus" :prizes="getPrizes" @close="openPrizes = false" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StatusBar from './StatusBar'
import Prizes from './Prizes'
export default {
  name: 'Buy-Get',
  components: { StatusBar, Prizes },
  data () {
    return {
      openPrizes: false
    }
  },
  computed: {
    ...mapGetters({
      getStatus: 'buyGet/getStatus',
      getPrizes: 'buyGet/getPrizes'
    }),
    userIsParticipant () {
      return this.getStatus.is_participant
    }
  },
  watch: {
    openPrizes () {
      this.calculateBodyHeight()
    }
  },
  methods: {
    ...mapActions({
      getUserStatus: 'buyGet/getUserStatus',
      calculateBodyHeight: 'chat/calculateBodyHeight'
    })
  },
  mounted () {
    this.getUserStatus()
  }
}
</script>

<style lang="scss">
.buy-get {
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 5px 15px 15px 15px;
  background: #fff;
  padding-top: 10px;
  &.opened {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  @keyframes pulse-animation-1 {
    0% {
      box-shadow: 0 0 0 0 rgba(2, 119, 189, 0.4);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(2, 119, 189, 0);
    }
  }

  @keyframes pulse-animation-2 {
    0% {
      box-shadow: 0 0 0 0 rgba(2, 119, 189, 0.4);
    }
    100% {
      box-shadow: 0 0 0 5px rgba(2, 119, 189, 0);
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
